<template>
    <div class="page">
        <div class="title">企业合同列表</div>

        <el-divider></el-divider>

        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search">
                <!-- select -->
                <el-select
                    class="filter_search_select"
                    v-model="search.type"
                    @change="toSearch"
                >
                    <el-option
                        v-for="item in type_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>

                <!-- input -->
                <el-input
                    class="filter_search_input"
                    placeholder="请输入关键词"
                    v-model="search.keyword"
                    clearable
                >
                </el-input>
                <div class="custom_button no_select" @click="toSearch">搜索</div>
            </div>
            <div class="filter_btns">
                <!-- <div class="custom_button no_select">提醒签署</div> -->
            </div>
        </div>

        <!-- table -->
        <el-table
            id="table"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column
                prop="fddUserInfo.b.staffInfo.real_name"
                label="公司名"
                min-width="150"
            ></el-table-column>
            <el-table-column prop="title" label="使用合同"></el-table-column>
            <el-table-column
                prop="contract_id"
                label="合同编号"
                min-width="300"
            ></el-table-column>
            <el-table-column
                prop="otherInfo.sign_status_desc"
                label="签署状态"
            ></el-table-column>
            <el-table-column
                prop="signLogInfo.b.sign_time"
                label="签署时间"
            ></el-table-column>
            <el-table-column
                label="操作"
                min-width="120"
                fixed="right"
                v-if="search.type == 2"
            >
                <template v-slot:default="scope">
                    <div class="operations">
                        <div @click="toSign(scope.row.signloglist.b.sign_url)">
                            签署合同
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.page"
            @current-change="loadData"
        >
        </el-pagination>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            search: {
                keyword: "",
                type: 1,
            },
            tableData: [],
            type_options: [
                {
                    label: "我公司向公司发起的",
                    value: 1,
                },
                {
                    label: "其他公司向我公司发起的",
                    value: 2,
                },
            ],
            page: {
                size: 15,
                page: 1,
                total: 0,
            },

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    inject: ["reload"],
    created() {
        this.loadData = this.loadDataFactory();
        this.loadData();
    },
    methods: {
        ...mapActions("contract", ["getEnterpriseContractList"]),

        loadDataFactory(keyword = null, type = this.search.type) {
            let params = {};
            if (keyword) {
                params.keywords = keyword;
            }
            if (type) params.type = type;
            return () => {
                params.page = this.page.page;
                let loading = this.$loading();
                this.getEnterpriseContractList(params)
                    .then((res) => {
                        console.log(res);
                        this.tableData = res.data.data.list;
                        this.page.total = res.data.data.total;
                        this.$nextTick(() => {
                            loading.close();
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$message.error(e.msg);
                        this.$nextTick(() => {
                            loading.close();
                        });
                    });
            };
        },

        /**
         * 搜索
         */
        toSearch() {
            this.page.page = 1;
            // 生产带有搜索参数的函数
            this.loadData = this.loadDataFactory(
                this.search.keyword,
                this.search.type
            );
            this.loadData();
        },

        toSign(url) {
            window.open(url, "_blank");
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_select {
            width: 200px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }

            ::v-deep .el-input__icon {
                line-height: 36px;
            }
        }

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
